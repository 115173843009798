<template>  
 <div>
  <div class="top hidden-xs">
        <div class="topitem ">
            <div class="con1400">
                <div class="head flexrow">
                    <div class="logo">
                        <a class="flexrow" href="/">
                <img src="../ldcms2024/images/indexLogo2.png" alt="苏州竹子网络科技有限公司" style="height: 100px;">
              </a>
                    </div>
                    <div class="head_r flexrow">
                        <div class="nav">
                            <ul class="flexrow">
                                <li class="">
                                  <router-link to="/">网站首页</router-link>  
                                </li>
                                <li class=" active ">
                                    <router-link to="/aboutUs">关于我们</router-link>   
                                    <div class="ernav">
                                        <div class="con1400">
                                            <div class="ernavitem flexrow">
                                                <div class="ernav_l">
                                                    <h3>
                                                        about<i>/</i><span>关于我们</span>
                                                    </h3>
                                                    <p>全套供热系统集成商、清洁智能锅炉提供商及国内供热服务商</p>
                                                    <ul class="ernavlink flexrow">
                                                        <li>
                                                            <a href="/aboutUs" title="公司简介">公司简介</a>
                                                        </li>
                                                        <li>
                                                            <a href="/aboutUs#abqywh" title="企业文化">企业文化</a>
                                                        </li>
                                                        <li>
                                                            <a href="/aboutUs#abfzlc" title="发展历程">发展历程</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="ernav_r">
                                                    <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/banner.jpg" alt="关于我们">
                                                    <p>"智慧 低碳 多元化”</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="">
                                    <a href="/product" title="产品中心">产品中心</a>
                                    <div class="ernav">
                                        <div class="con1400">
                                            <div class="ernavitem flexrow">
                                                <div class="ernav_l">
                                                    <h3>
                                                        Products<i>/</i><span>产品中心</span>
                                                    </h3>
                                                    <p>全套供热系统集成商、清洁智能锅炉提供商及国内供热服务商</p>
                                                    <ul class="ernavlink flexrow">
                                                        <li>
                                                            <a href="/product" title="产品系列1">产品系列1</a>
                                                        </li>
                                                        <li>
                                                            <a href="/productSubpage" title="产品系列2">产品系列2</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="ernav_r">
                                                    <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/banner.jpg" alt="产品中心">
                                                    <p>"智慧 低碳 多元化”</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="">
                                    <a href="/solution" title="解决方案">解决方案</a>
                                    <div class="ernav">
                                        <div class="con1400">
                                            <div class="ernavitem flexrow">
                                                <div class="ernav_l">
                                                    <h3>
                                                        Solution<i>/</i><span>解决方案</span>
                                                    </h3>
                                                    <p>全套供热系统集成商、清洁智能锅炉提供商及国内供热服务商</p>
                                                    <ul class="ernavlink flexrow">
                                                        <li>
                                                            <a href="/solution" title="行业方案">行业方案</a>
                                                        </li>
                                                        <li>
                                                            <a href="/solution#fashzr" title="社会责任">社会责任</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="ernav_r">
                                                    <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/banner.jpg" alt="解决方案">
                                                    <p>"智慧 低碳 多元化”</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="">
                                    <a href="/serviceAndSupport" title="服务与支持">服务与支持</a>
                                    <div class="ernav">
                                        <div class="con1400">
                                            <div class="ernavitem flexrow">
                                                <div class="ernav_l">
                                                    <h3>
                                                        Service<i>/</i><span>服务与支持</span>
                                                    </h3>
                                                    <p>全套供热系统集成商、清洁智能锅炉提供商及国内供热服务商</p>
                                                    <ul class="ernavlink flexrow">
                                                        <li>
                                                            <a href="/serviceAndSupport" title="服务案例">服务案例</a>
                                                        </li>
                                                        <li>
                                                            <a href="/downloadCenter" title="下载中心">下载中心</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="ernav_r">
                                                    <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024//images/banner.jpg" alt="服务与支持">
                                                    <p>"智慧 低碳 多元化”</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="">
                                    <a href="/newsCenter" title="新闻中心">新闻中心</a>
                                    <div class="ernav">
                                        <div class="con1400">
                                            <div class="ernavitem flexrow">
                                                <div class="ernav_l">
                                                    <h3>
                                                        News<i>/</i><span>新闻中心</span>
                                                    </h3>
                                                    <p>全套供热系统集成商、清洁智能锅炉提供商及国内供热服务商</p>
                                                    <ul class="ernavlink flexrow">
                                                        <li>
                                                            <a href="/newsCenter" title="公司新闻">公司新闻</a>
                                                        </li>
                                                        <li>
                                                            <a href="/industryNews" title="行业资讯">行业资讯</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="ernav_r">
                                                    <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/banner.jpg" alt="新闻中心">
                                                    <p>"智慧 低碳 多元化”</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="">
                                    <a href="/contactUs" title="联系我们">联系我们</a>
                                </li>
                            </ul>
                        </div>
                        <div class="top_r flexrow">
                            <div class="top_lang flexrow">
                                <img src="../ldcms2024/images/langico.png" alt="语言切换">
                                <div class="langitem">
                                    <a class="flexrow" href="/?lg=zh-cn">
                      <img src="../ldcms2024/images/zh-cn.png" alt="简体中文">
                      <span>简体中文</span>
                    </a>
                                    <a class="flexrow" href="/?lg=en">
                      <img src="../ldcms2024/images/en.png" alt="English">
                      <span>English</span>
                    </a>
                                </div>
                            </div>
                            <div class="top_search">
                  <img class="top_searchico" src="../ldcms2024/images/searico.png"
                    alt>
                  <div class="searchitem">
                    <div class="con1400">
                      <form class="searchform flexrow" method="get"
                        action="/contactUs">
                        <input type="text" name="search" placeholder="请输入关键字">
                        <input  name="cid" type="hidden" value="128,144,147">
                        <button type="submit">搜索</button>
                        <div class="searchclose">
                          <img src="../ldcms2024/images/search_close.png" alt>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="waptop hidden-sm hidden-md hidden-lg">
        <div class="waptop_item">
            <div class="con1400">
                <div class="waptopitem flexrow">
                    <div class="waptop_l">
                        <a class="flexrow" href="/">
                <img src="../ldcms2024/images/indexLogo2.png" alt="苏州竹子网络科技有限公司">
              </a>
                    </div>
                    <div class="waptop_r flexrow">
                        <div class="waptop_lang flexrow">

                            <p class="waptop_langa">ENG</p>
                            <div class="langitem">
                                <a class="flexrow" href="/?lg=zh-cn">
                    <img src="../ldcms2024/images/zh-cn.png" alt="简体中文">
                    <span>简体中文</span>
                  </a>
                                <a class="flexrow" href="/?lg=en">
                    <img src="../ldcms2024/images/en.png" alt="English">
                    <span>English</span>
                  </a>
                            </div>
                        </div>
                        <div class="waptop_rs">
                            <img src="../ldcms2024/images/searchico.png" alt>
                        </div>
                        <div class="waptop_rnav">
                            <img src="../ldcms2024/images/wapnavico.png" alt>
                        </div>
                    </div>
                    <div class="wapnav">
                        <div class="wapnavitem">
                            <div class="con1400">
                                <div class="wapnavtop flexrow">
                                    <div class="wapnavt_l">
                                        <a class="flexrow" href="/">
                        <img src="../ldcms2024/images/indexLogo2.png" alt="苏州竹子网络科技有限公司">
                      </a>
                                    </div>
                                    <div class="wapnavt_r">
                                        <img src="../ldcms2024/images/search_close.png" alt>
                                    </div>
                                </div>
                                <div class="wapnavbot">
                                    <ul>
                                        <li>
                                            <a href="/">网站首页</a>
                                        </li>
                                        <li class="flexrow">
                                            <a href="/aboutUs">关于我们</a>
                                            <img class="wapnav_ico" src="../ldcms2024/images/jiantblue.png" alt>
                                            <div class="wapernav">
                                                <a href="/aboutUs" title="公司简介">公司简介</a>
                                                <a href="/aboutUs#abqywh" title="企业文化">企业文化</a>
                                                <a href="/aboutUs#abfzlc" title="发展历程">发展历程</a>
                                            </div>
                                        </li>
                                        <li class="flexrow">
                                            <a href="/product">产品中心</a>
                                            <img class="wapnav_ico" src="../ldcms2024/images/jiantblue.png" alt>
                                            <div class="wapernav">
                                                <a href="/product" title="产品系列1">产品系列1</a>
                                                <a href="/productSubpage" title="产品系列2">产品系列2</a>
                                            </div>
                                        </li>
                                        <li class="flexrow">
                                            <a href="/solution">解决方案</a>
                                            <img class="wapnav_ico" src="../ldcms2024/images/jiantblue.png" alt>
                                            <div class="wapernav">
                                                <a href="/solution" title="行业方案">行业方案</a>
                                                <a href="/solution#fashzr" title="社会责任">社会责任</a>
                                            </div>
                                        </li>
                                        <li class="flexrow">
                                            <a href="/serviceAndSupport">服务与支持</a>
                                            <img class="wapnav_ico" src="../ldcms2024/images/jiantblue.png" alt>
                                            <div class="wapernav">
                                                <a href="/serviceAndSupport" title="服务案例">服务案例</a>
                                                <a href="/downloadCenter" title="下载中心">下载中心</a>
                                            </div>
                                        </li>
                                        <li class="flexrow">
                                            <a href="/newsCenter">新闻中心</a>
                                            <img class="wapnav_ico" src="../ldcms2024/images/jiantblue.png" alt>
                                            <div class="wapernav">
                                                <a href="/newsCenter" title="公司新闻">公司新闻</a>
                                                <a href="/industryNews" title="行业资讯">行业资讯</a>
                                            </div>
                                        </li>
                                        <li class="flexrow">
                                            <a href="/contactUs">联系我们</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="wapsearch">
                        <div class="wapsea_item flexrow">
                            <form class="flexrow" method="get" action="/search.html">
                                <input type="text" name="search" id placeholder="请输入关键字">
                                <input name="cid" type="hidden" value="128,144,147">
                                <button type="submit">
                    <img src="../ldcms2024/images/searchico.png" alt>
                  </button>
                                <div class="wapsea_close">
                                    <img src="../ldcms2024/images/search_close.png" alt>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- top end -->

    <!-- banner -->
    <div class="banner">
        <div class="neibanner">
            <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/banner.jpg" alt="关于我们">
            <div class="neibannerdesc flexrow">
                <h2>关于我们</h2>
            </div>
        </div>
    </div>
    <!-- banner end -->

    <!-- 内页导航 -->
    <section class="neinav">
        <div class="con1400">
            <div class="neinav_item flexrow">
                <ul class="flexrow">
                    <li class=" active ">
                        <a href="/aboutUs" title="公司简介">公司简介</a>
                    </li>
                    <li class="">
                        <a href="/aboutUs#abqywh" title="企业文化">企业文化</a>
                    </li>
                    <li class="">
                        <a href="/aboutUs#abfzlc" title="发展历程">发展历程</a>
                    </li>
                </ul>
                <div class="casetop_r">
                    <p>
                        <a href="/">首页</a> >
                        <a href="/aboutUs">关于我们</a> >
                        <a href="/aboutUs">公司简介</a>
                    </p>
                </div>
            </div>
        </div>
    </section>
    <!-- 内页导航 end -->

    <!-- 主体 -->
    <section class="neimain">
        <div class="abouttop">
            <div class="container con1400 animate" data-animate="fadeInUp" data-duration="1.5s" data-delay="0.1s">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div class="aboutt_l">
                            <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/banner.jpg" alt="专注网站建设，用心服务客户">
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                        <div class="aboutt_r">
                            <h3>专注网站建设，用心服务客户</h3>
                            <p>
                                历经10年的成长，我公司拥有一批资深的网页设计师与网站开发程序员，具有强大的技术力量。他们都是一批来自信息技术前沿阵地的优秀年轻人，团结务实的工作态度，富有激情的设计理念和创作情神，一贯的以客户需求为导向，广泛赢得了业界的赞誉和客户的信任。依托我公司强大的设计团队，可承接各大中小型公司网站，政府网站、集团网站、电子商务网站，网站功能及美工设计可根据客户的不同需要进行量身订制。并对所服务的客户网站维护及相应的营销推广指导进行一对一的贴身服务，充分让客户感受个性化服务带来的便利和价值。<br
                                /> </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="about_qywh" id="abqywh">
            <div class="slideTxtBox">
                <div class="hd">
                    <ul class="con1400">
                        <li class=" animate" data-animate="fadeIn" data-duration="1.5s" data-delay="0.1s">
                            <div class="about_qywhd_desc">
                                <h3>愿景</h3>
                                <p>成为客户专业的合作伙伴</p>
                            </div>
                            <div class="about_qywhd_line"></div>
                        </li>
                        <li class=" animate" data-animate="fadeIn" data-duration="1.5s" data-delay="0.1s">
                            <div class="about_qywhd_desc">
                                <h3>使命</h3>
                                <p>助力客户提升生产力</p>
                            </div>
                            <div class="about_qywhd_line"></div>
                        </li>
                        <li class=" animate" data-animate="fadeIn" data-duration="1.5s" data-delay="0.1s">
                            <div class="about_qywhd_desc">
                                <h3>企业精神</h3>
                                <p>正直、友善；激情、坚韧；创新、合作；</p>
                            </div>
                            <div class="about_qywhd_line"></div>
                        </li>
                        <li class=" animate" data-animate="fadeIn" data-duration="1.5s" data-delay="0.1s">
                            <div class="about_qywhd_desc">
                                <h3>价值观</h3>
                                <p>成就客户；发展奋斗者；为社会做贡献；</p>
                            </div>
                            <div class="about_qywhd_line"></div>
                        </li>
                    </ul>
                </div>
                <div class="bd">
                    <ul>
                        <li>
                            <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/banner.jpg" alt="愿景">
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/idxabout.jpg" alt="使命">
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/banner.jpg" alt="企业精神">
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/idxfaimg1.jpg" alt="价值观">
                        </li>
                    </ul>
                </div>
            </div>
            <!-- <script type="text/javascript">
                jQuery(".slideTxtBox").slide({
                    autoPlay: false,
                    delayTime: '1500'
                });
            </script> -->
        </div>

        <div class="about_his" id="abfzlc">
            <div class="con1400">
                <div class="about_hisitem animate" data-animate="fadeInUp" data-duration="1.5s" data-delay="0.1s">
                    <div class="about_hisitem_t">
                        <h3>发展历程</h3>
                    </div>
                    <!-- Swiper -->
                    <div class="swiper-container gallery-top abouthis_top">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <div class="about_histop flexrow">
                                    <div class="abouthist_l">
                                        <h3>2023</h3>
                                        <p>
                                            1.苏州新总部启用<br /> 2.进入充换电站领域 </p>
                                    </div>
                                    <div class="abouthist_r">
                                        <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/idxabout.jpg" alt="2023">
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="about_histop flexrow">
                                    <div class="abouthist_l">
                                        <h3>2022</h3>
                                        <p>
                                            1.苏州新总部启用<br /> 2.进入充换电站领域 </p>
                                    </div>
                                    <div class="abouthist_r">
                                        <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/idxabout.jpg" alt="2022">
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="about_histop flexrow">
                                    <div class="abouthist_l">
                                        <h3>2021</h3>
                                        <p>
                                            1.苏州新总部启用<br /> 2.进入充换电站领域 </p>
                                    </div>
                                    <div class="abouthist_r">
                                        <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/idxabout.jpg" alt="2021">
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="about_histop flexrow">
                                    <div class="abouthist_l">
                                        <h3>2020</h3>
                                        <p>
                                            1.苏州新总部启用<br /> 2.进入充换电站领域 </p>
                                    </div>
                                    <div class="abouthist_r">
                                        <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/idxabout.jpg" alt="2020">
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="about_histop flexrow">
                                    <div class="abouthist_l">
                                        <h3>2019</h3>
                                        <p>
                                            1.苏州新总部启用<br /> 2.进入充换电站领域 </p>
                                    </div>
                                    <div class="abouthist_r">
                                        <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/idxabout.jpg" alt="2019">
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="about_histop flexrow">
                                    <div class="abouthist_l">
                                        <h3>2018</h3>
                                        <p>
                                            1.苏州新总部启用<br /> 2.进入充换电站领域 </p>
                                    </div>
                                    <div class="abouthist_r">
                                        <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/idxabout.jpg" alt="2018">
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="about_histop flexrow">
                                    <div class="abouthist_l">
                                        <h3>2017</h3>
                                        <p>
                                            1.苏州新总部启用<br /> 2.进入充换电站领域 </p>
                                    </div>
                                    <div class="abouthist_r">
                                        <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/idxabout.jpg" alt="2017">
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="about_histop flexrow">
                                    <div class="abouthist_l">
                                        <h3>2016</h3>
                                        <p>
                                            1.苏州新总部启用<br /> 2.进入充换电站领域 </p>
                                    </div>
                                    <div class="abouthist_r">
                                        <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/idxabout.jpg" alt="2016">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-container gallery-thumbs abouthis_bot">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <div class="about_hisbot_c"></div>
                                <div class="about_hisbot_y">
                                    <h4>2023</h4>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="about_hisbot_c"></div>
                                <div class="about_hisbot_y">
                                    <h4>2022</h4>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="about_hisbot_c"></div>
                                <div class="about_hisbot_y">
                                    <h4>2021</h4>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="about_hisbot_c"></div>
                                <div class="about_hisbot_y">
                                    <h4>2020</h4>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="about_hisbot_c"></div>
                                <div class="about_hisbot_y">
                                    <h4>2019</h4>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="about_hisbot_c"></div>
                                <div class="about_hisbot_y">
                                    <h4>2018</h4>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="about_hisbot_c"></div>
                                <div class="about_hisbot_y">
                                    <h4>2017</h4>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="about_hisbot_c"></div>
                                <div class="about_hisbot_y">
                                    <h4>2016</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Add Arrows -->
                    <div class="swiper-button-next swiper-button-white abouthist_next"></div>
                    <div class="swiper-button-prev swiper-button-white abouthist_prev"></div>
                </div>
            </div>

            <!-- 历程横线 -->
            <div class="about_hisline"></div>
        </div>
    </section>
    <!-- 主体 end -->

 <!-- 底部表单 -->
 <section class="idx_footmess animate"
    data-animate="fadeInUp" data-duration="1.5s" data-delay="0.1s">
      <div class="con1400">
                <div class="idx_ftitles">
          <h3>专业技术团队 提供全方位系统解决方案</h3>
          <p>如有任何疑问 请随时与我们联系</p>
        </div>
                <div class="idx_fmess">
          <form class="idx_fform flexrow">
            <input type="text" name="uname" v-model="footName" placeholder="您的姓名">
            <input type="text" name="mobile" v-model="footPhone" placeholder="您的电话">
            <input class="idx_fform3" type="text" v-model="footContent" name="remark" placeholder="您的留言">
            <!-- <input type="hidden" name="__token__"  value="287ea90fccc6d81e29012d9beae40b71" />             -->
            <!-- <button type="submit">立即提交</button> -->
            <el-button :plain="true" @click="footOpen" type="primary">立即提交</el-button>
          </form>
        </div>
      </div>
    </section>
  <!-- 底部表单 end -->

  <!-- 底部导航 -->
   <section class="foot">
      <div class="con1400">
        <div class="foottop flexrow">
          <div class="footlogo">
            <a href="/">
              <img src="../ldcms2024/images/indexLogo2.png" alt="苏州竹子网络科技有限公司">
            </a>
          </div>
          <div class="footlinks">
            <ul class="flexrow">
              <li>
                <a href="JavaScript:;">
                  <img class="fewmico" src="../ldcms2024/images/douyin.svg" alt>
                </a>
                <div class="fewmimg">
                  <img src="../ldcms2024/images/wxpicture.jpg" alt="">
                  <p>抖音二维码</p>
                </div>
              </li>
              <li>
                <a href="JavaScript:;">
                  <img class="fewmico" src="../ldcms2024/images/weixin.svg" alt>
                </a>
                <div class="fewmimg">
                  <img src="../ldcms2024/images/wxpicture.jpg" alt="">
                  <p>微信公众号</p>
                </div>
              </li>
              <li>
                <a href="JavaScript:;">
                  <img class="fewmico" src="../ldcms2024/images/qq.svg" alt>
                </a>
                <div class="fewmimg">
                  <img src="../ldcms2024/images/wxpicture.jpg" alt="">
                  <p>QQ群:961718787</p>
                </div>
              </li>
              <li>
                <a href="JavaScript:;">
                  <img class="fewmico" src="../ldcms2024/images/xhs.svg" alt>
                </a>
                <div class="fewmimg">
                  <img src="../ldcms2024/images/wxpicture.jpg" alt="">
                  <p>小红书二维码</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="footmid flexrow">
          <div class="footmid_l flexrow">
                        <dl>
              <dt><a href="/aboutUs"
                  title="关于我们">关于我们</a></dt>
                            <dd><a href="/aboutUs"
                  title="公司简介">公司简介</a></dd>
                            <dd><a href="/aboutUs#abqywh"
                  title="企业文化">企业文化</a></dd>
                            <dd><a href="/aboutUs#abfzlc"
                  title="发展历程">发展历程</a></dd>
                          </dl>
                        <dl>
              <dt><a href="/product"
                  title="产品中心">产品中心</a></dt>
                            <dd><a href="/product"
                  title="产品系列1">产品系列1</a></dd>
                            <dd><a href="/productSubpage"
                  title="产品系列2">产品系列2</a></dd>
                          </dl>
                        <dl>
              <dt><a href="/solution"
                  title="解决方案">解决方案</a></dt>
                            <dd><a href="/solution"
                  title="行业方案">行业方案</a></dd>
                            <dd><a href="/solution#fashzr"
                  title="社会责任">社会责任</a></dd>
                          </dl>
                        <dl>
              <dt><a href="/serviceAndSupport"
                  title="服务与支持">服务与支持</a></dt>
                            <dd><a href="/serviceAndSupport"
                  title="服务案例">服务案例</a></dd>
                            <dd><a href="/downloadCenter"
                  title="下载中心">下载中心</a></dd>
                          </dl>
                        <dl>
              <dt><a href="/newsCenter"
                  title="新闻中心">新闻中心</a></dt>
                            <dd><a href="/newsCenter"
                  title="公司新闻">公司新闻</a></dd>
                            <dd><a href="/industryNews"
                  title="行业资讯">行业资讯</a></dd>
                          </dl>
                        <dl>
              <dt><a href="/contactUs"
                  title="联系我们">联系我们</a></dt>
                          </dl>
                      </div>
          <div class="footmid_r">
            <ul>
              <li>
                <div class="footmid_rdesc">
                  <p>4006-711-886</p>
                </div>
              </li>
              <li>
                <div class="footmid_rdesc">
                  <p>苏州市相城区相城大道666号中翔大厦15012</p>
                </div>
              </li>
              <li>
                <div class="footmid_rdesc">
                  <p>erweima@foxmail.com</p>
                </div>
              </li>
            </ul>
            <div class="footmid_ewm ">
              <ul class="flexrow">
                <li>
                  <img src="../ldcms2024/images/wxpicture.jpg" alt>
                  <p>咨询微信客服</p>
                </li>
                <li>
                  <img src="../ldcms2024/images/wxpicture.jpg" alt>
                  <p>微信公众号</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="footcopy">
      <div class="con1400">
        <div class="footcitem flexrow">
          <div class="footc_l">
            <span class="footc_ldesc">© 2011-2024 苏州竹子网络科技有限公司 版权所有</span>
            <span>备案号：<a href="https://beian.miit.gov.cn/"
                target="_blank" rel="noopener noreferrer">苏ICP备14060880号</a></span>
                              <span><a href="/index" target="_blank">免责声明</a></span>
                            <span><a href="/index" target="_blank">隐私协议</a></span>
                        </div>
          <div class="footc_r">
            <p>友情链接</p>
            <div class="footc_rlinks">
              <ul>
                                <li><a href="/index" target="_blank">LDcms网站系统</a></li>
                                <li><a href="/index" target="_blank">苏州网站建设</a></li>
                                <li><a href="/index" target="_blank">苏州网站模板</a></li>
                              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="xuanfu">
        <ul>
          <li class="xfwxitem">
            <a href="	" target="_blank">
              <div class="xfimg flexrow">
                <img src="../ldcms2024/images/xfico1.png" alt>
              </div>
              <div class="xfwx">
                <img src="../ldcms2024/images/wxpicture.jpg" alt="咨询微信客服">
                <p class="xfwxdesc">咨询微信客服</p>
              </div>
            </a>
          </li>
          <li class="xftelitem">
            <div class="xfimg flexrow">
              <img src="../ldcms2024/images/xfico2.png" alt>
            </div>
            <div class="xfwx xftel">
              <p>4006-711-886</p>
              <span class="xfteldesc">立即获取方案或咨询</span>
            </div>
          </li>
          <li class="xftopitem">
            <div class="xfimg flexrow" id="goToTop">
              <img src="../ldcms2024/images/xfico3.png" alt>
              <p>top</p>
            </div>
          </li>
        </ul>
      </div>
    </section>
  <!-- 底部导航 end -->
 </div>
</template>  
  
<script>    
// 引入Swiper和Swiper的CSS（通常需要引入CSS以确保样式正确）  
import Swiper from 'swiper';  
// import 'swiper/swiper-bundle.css'; 
import jQuery from 'jquery';
import axios from 'axios';
let _this = null
export default {  
  name: 'DraggableCarousel',  

  data() {  
    return { 
        // 底部表单
        footName: '',  
        footPhone: '',  
        footContent: '',

        baseUrl:this.GLOBAL.BASE_URL,
        }
    },

  methods: {
    footOpen() {  
      axios.post(this.baseUrl+'contactUs/addContactUs',{
        name:this.footName,
        phone:this.footPhone,
        content:this.footContent
      },{
        headers: {  
        'Content-Type': 'application/json'  
        }  
       })  
    .then(response => {  
          // 处理成功响应，例如显示成功消息 
        console.log('Form submitted successfully', response.data); 
        if(response.data.code==474){
          this.$message({
          message: response.data.msg,
          type: 'warning'
        }); 
        }
        else if(response.data.code==475){
          this.$message({
          message: response.data.msg,
          type: 'warning'
        }); 
        }
        else if(response.data.code==476){
          this.$message({
          message: response.data.msg,
          type: 'error'
        }); 
        }
        else if(response.data.code==472){
          this.$message({
          message: response.data.msg,
          type: 'warning'
        }); 
        }
        else if(response.data.code==200){
          this.$message({
          message: response.data.msg,
          type: 'success'
        }); 
        }

      this.footResetForm();

        })
     .catch(error => {  
      // 处理错误  
      console.error(error);  
    });  

    },

    footResetForm() {  
        this.footName = '';  
        this.footPhone = '';  
        this.footContent = '' ;
    },
    },

  mounted(){
        
        // eslint-disable-next-line no-unused-vars
        var idxkh_swiper = new Swiper('idxkh_swiper', {
                slidesPerView: 6,
                slidesPerColumn: 2,
                spaceBetween: 30,
                // loop: true,
                pagination: {
                    el: '.idxkh_spagination',
                    clickable: true,
                },

                breakpoints: {
                    320: { //当屏幕宽度大于等于320
                        slidesPerView: 2,
                        spaceBetween: 10
                    },
                    768: { //当屏幕宽度大于等于768 
                        slidesPerView: 4,
                        spaceBetween: 20
                    },
                    1280: { //当屏幕宽度大于等于1280
                        slidesPerView: 6,
                        spaceBetween: 30
                    }
                },
            });
      
        var abouthis_bot = new Swiper('.abouthis_bot', {
            spaceBetween: 10,
            slidesPerView: 7,
            // loop: true,
            freeMode: true,
            loopedSlides: 5, //looped slides should be the same
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            breakpoints: {
                320: { //当屏幕宽度大于等于320
                    slidesPerView: 3,
                },
                768: { //当屏幕宽度大于等于768 
                    slidesPerView: 5,
                },
                1280: { //当屏幕宽度大于等于1280
                    slidesPerView: 7,
                }
            }
        });
        
        // eslint-disable-next-line no-unused-vars
        var abouthis_top = new Swiper('.abouthis_top', {
            spaceBetween: 10,
            // loop:true,
            loopedSlides: 5, //looped slides should be the same
            navigation: {
                nextEl: '.abouthist_next',
                prevEl: '.abouthist_prev',
            },
            thumbs: {
                swiper: abouthis_bot,
            },
        });
    
    jQuery(document).ready(function() {

      // 电脑导航下滑时候样式
      jQuery(function() {
          var nav = jQuery(".top"); //得到导航对象baidu
          var win = jQuery(window); //得到窗口对象
          var sc = jQuery(document); //得到document文档对象。zhi
          win.scroll(function() {
              if (sc.scrollTop() >= 60) {
                  nav.addClass("fixednav");
                  jQuery(".xuanfu").fadeIn();
              } else {
                  nav.removeClass("fixednav");
                  jQuery(".xuanfu").fadeOut();
              }
          });
      });

      // 手机导航下滑时候样式
      jQuery(function() {
          var wapnav = jQuery(".waptop"); //得到导航对象baidu
          var wapwin = jQuery(window); //得到窗口对象
          var wapsc = jQuery(document); //得到document文档对象。zhi
          wapwin.scroll(function() {
              if (wapsc.scrollTop() >= 60) {
                  wapnav.addClass("fixednav");
                  // $(".xuanfu").fadeIn();
              } else {
                  wapnav.removeClass("fixednav");
                  // $(".xuanfu").fadeOut();
              }
          });
      });

      // 顶部搜索
      jQuery('.top_searchico').click(function() {
        jQuery('.searchitem').fadeIn();
      });
      jQuery('.searchclose').click(function() {
        jQuery('.searchitem').hide();
      });

      // 电脑导航下拉
      jQuery(".nav>ul>li").mouseenter(function() {
        jQuery(_this)
              .find(".ernav")
              .slideDown()
              .end()
              .siblings("li")
              .find(".ernav")
              .hide();
      });
      jQuery(".ernav").mouseleave(function() {
        jQuery(this).hide();
      });

      // 返回顶部
      jQuery('#goToTop').click(function() {
        jQuery('html , body').animate({
              scrollTop: 0
          }, 'slow');
      });

      // 手机端语言切换
      jQuery('.waptop_langa').click(function() {
        jQuery('.langitem').fadeToggle();
      })

      // 手机端搜索效果
      jQuery('.waptop_rs').click(function() {
        jQuery('.wapsearch').fadeIn();
      });
      jQuery('.wapsea_close').click(function() {
        jQuery('.wapsearch').hide();
      });

      // 手机端导航栏目下拉效果
      jQuery('.wapnav_ico').click(function() {
        jQuery(this).toggleClass('active').parent('li').siblings().find('.wapnav_ico').removeClass('active');
        jQuery(this).next('.wapernav').slideToggle().end().parent('li').siblings().find('.wapernav').slideUp();
      });

      // 手机端导航下拉效果
      jQuery('.waptop_rnav').click(function() {
        jQuery('.wapnav').slideDown();
      })
      jQuery('.wapnavt_r').click(function() {
        jQuery('.wapnav').hide();
      });

  })
  
                                
 
                
           
  }
}
</script> 
  
<style>  

</style>
eslint-disable no-unused-vars
<template>

<div>
<!-- top -->
<div class="top hidden-xs">
      <div class="topitem ">
        <div class="con1400">
          <div class="head flexrow">
            <div class="logo">
              <a class="flexrow" href="/">
                <img src="../ldcms2024/images/indexLogo2.png" alt="苏州竹子网络科技有限公司" style="height: 100px;">
              </a>
            </div>
            <div class="head_r flexrow">
              <div class="nav">
                <ul class="flexrow">
                  <li class=" active ">
                    <!-- <a href="/">网站首页</a> -->
                    <router-link to="/">网站首页</router-link>  
                  </li>
                                    <li class="">
                    <router-link to="/aboutUs">关于我们</router-link>                
                    <!-- <a href="/gongsijianjie.html" title="关于我们">关于我们</a> -->
                                        <div class="ernav">
                      <div class="con1400">
                        <div class="ernavitem flexrow">
                          <div class="ernav_l">
                            <h3>
                              about<i>/</i><span>关于我们</span>
                            </h3>
                            <p>全套供热系统集成商、清洁智能锅炉提供商及国内供热服务商</p>
                            <ul class="ernavlink flexrow">
                                                            <li>
                                <a href="/aboutUs"
                                  title="公司简介">公司简介</a>
                              </li>
                                                            <li>
                                <a href="/aboutUs#abqywh"
                                  title="企业文化">企业文化</a>
                              </li>
                                                            <li>
                                <a href="/aboutUs#abfzlc"
                                  title="发展历程">发展历程</a>
                              </li>
                                
                                                          </ul>
                          </div>
                          <div class="ernav_r">
                            <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/banner.jpg" alt="关于我们">
                            <p>"智慧 低碳 多元化”</p>
                          </div>
                        </div>
                      </div>
                    </div>
                                      </li>
                                    <li class="">
                   <router-link to="/product">产品中心</router-link>
                    <!-- <a href="/chanpinxilie1.html" title="产品中心">产品中心</a> -->
                                        <div class="ernav">
                      <div class="con1400">
                        <div class="ernavitem flexrow">
                          <div class="ernav_l">
                            <h3>
                              Products<i>/</i><span>产品中心</span>
                            </h3>
                            <p>全套供热系统集成商、清洁智能锅炉提供商及国内供热服务商</p>
                            <ul class="ernavlink flexrow">
                                                            <li>
                                <a href="/product"
                                  title="产品系列1">产品系列1</a>
                              </li>
                                                            <li>
                                <a href="/productSubpage"
                                  title="产品系列2">产品系列2</a>
                              </li>
                                                          </ul>
                          </div>
                          <div class="ernav_r">
                            <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/banner.jpg" alt="产品中心">
                            <p>"智慧 低碳 多元化”</p>
                          </div>
                        </div>
                      </div>
                    </div>
                                      </li>
                                    <li class="">
                     <router-link to="/solution">解决方案</router-link>                  
                    <!-- <a href="/hangyefangan.html" title="解决方案">解决方案</a> -->
                                        <div class="ernav">
                      <div class="con1400">
                        <div class="ernavitem flexrow">
                          <div class="ernav_l">
                            <h3>
                              Solution<i>/</i><span>解决方案</span>
                            </h3>
                            <p>全套供热系统集成商、清洁智能锅炉提供商及国内供热服务商</p>
                            <ul class="ernavlink flexrow">
                                                            <li>
                                <a href="/solution"
                                  title="行业方案">行业方案</a>
                              </li>
                                                            <li>
                                <a href="/solution#fashzr"
                                  title="社会责任">社会责任</a>
                              </li>
                                                          </ul>
                          </div>
                          <div class="ernav_r">
                            <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/banner.jpg" alt="解决方案">
                            <p>"智慧 低碳 多元化”</p>
                          </div>
                        </div>
                      </div>
                    </div>
                                      </li>
                                    <li class="">
                    <router-link to="/serviceAndSupport">服务与支持</router-link>                   
                    <!-- <a href="/fuwuanli.html" title="服务与支持">服务与支持</a> -->
                                        <div class="ernav">
                      <div class="con1400">
                        <div class="ernavitem flexrow">
                          <div class="ernav_l">
                            <h3>
                              Service<i>/</i><span>服务与支持</span>
                            </h3>
                            <p>全套供热系统集成商、清洁智能锅炉提供商及国内供热服务商</p>
                            <ul class="ernavlink flexrow">
                                                            <li>
                                <a href="/serviceAndSupport"
                                  title="服务案例">服务案例</a>
                              </li>
                                                            <li>
                                <a href="/downloadCenter"
                                  title="下载中心">下载中心</a>
                              </li>
                                                          </ul>
                          </div>
                          <div class="ernav_r">
                            <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024//images/banner.jpg" alt="服务与支持">
                            <p>"智慧 低碳 多元化”</p>
                          </div>
                        </div>
                      </div>
                    </div>
                                      </li>
                                    <li class="">
                    <router-link to="/newsCenter">新闻中心</router-link>
                                        <div class="ernav">
                      <div class="con1400">
                        <div class="ernavitem flexrow">
                          <div class="ernav_l">
                            <h3>
                              News<i>/</i><span>新闻中心</span>
                            </h3>
                            <p>全套供热系统集成商、清洁智能锅炉提供商及国内供热服务商</p>
                            <ul class="ernavlink flexrow">
                                                            <li>
                                <a href="/newsCenter"
                                  title="公司新闻">公司新闻</a>
                              </li>
                                                            <li>
                                <a href="/industryNews"
                                  title="行业资讯">行业资讯</a>
                              </li>
                                                          </ul>
                          </div>
                          <div class="ernav_r">
                            <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/banner.jpg" alt="新闻中心">
                            <p>"智慧 低碳 多元化”</p>
                          </div>
                        </div>
                      </div>
                    </div>
                                      </li>
                                    <li class="">
                    <router-link to="/contactUs">联系我们</router-link>
                    <!-- <a href="/lianxiwomen.html" title="联系我们">联系我们</a> -->
                                      </li>
                                  </ul>
              </div>
              <div class="top_r flexrow">
                <div class="top_lang flexrow">
                  <img src="../ldcms2024/images/langico.png" alt="语言切换">
                  <div class="langitem">
                                        <a class="flexrow"
                      href="/?lg=zh-cn">
                      <img src="../ldcms2024/images/zh-cn.png" alt="简体中文">
                      <span>简体中文</span>
                    </a>
                                        <a class="flexrow"
                      href="/?lg=en">
                      <img src="../ldcms2024/images/en.png" alt="English">
                      <span>English</span>
                    </a>
                                      </div>
                </div>
                <div class="top_search">
                  <img class="top_searchico" src="../ldcms2024/images/searico.png"
                    alt>
                  <div class="searchitem">
                    <div class="con1400">
                      <form class="searchform flexrow" method="get"
                        action="/contactUs">
                        <input type="text" name="search" placeholder="请输入关键字">
                        <input  name="cid" type="hidden" value="128,144,147">
                        <button type="submit">搜索</button>
                        <div class="searchclose">
                          <img src="../ldcms2024/images/search_close.png" alt>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="waptop hidden-sm hidden-md hidden-lg">
      <div class="waptop_item">
        <div class="con1400">
          <div class="waptopitem flexrow">
            <div class="waptop_l">
              <a class="flexrow" href="/">
                <img src="../ldcms2024/images/indexLogo2.png" alt="苏州竹子网络科技有限公司">
              </a>
            </div>
            <div class="waptop_r flexrow">
              <div class="waptop_lang flexrow">
                
                <p class="waptop_langa"  >ENG</p>
                <div class="langitem">
                                    <a class="flexrow"
                    href="/?lg=zh-cn">
                    <img src="../ldcms2024/images/zh-cn.png" alt="简体中文">
                    <span>简体中文</span>
                  </a>
                                    <a class="flexrow"
                    href="/?lg=en">
                    <img src="../ldcms2024/images/en.png" alt="English">
                    <span>English</span>
                  </a>
                                  </div>
              </div>
              <div class="waptop_rs">
                <img src="../ldcms2024/images/searchico.png" alt>
              </div>
              <div class="waptop_rnav">
                <img src="../ldcms2024/images/wapnavico.png" alt>
              </div>
            </div>
            <div class="wapnav">
              <div class="wapnavitem">
                <div class="con1400">
                  <div class="wapnavtop flexrow">
                    <div class="wapnavt_l">
                      <a class="flexrow"
                        href="/">
                        <img src="../ldcms2024/images/indexLogo2.png" alt="苏州竹子网络科技有限公司">
                      </a>
                    </div>
                    <div class="wapnavt_r">
                      <img src="../ldcms2024/images/search_close.png" alt>
                    </div>
                  </div>
                  <div class="wapnavbot">
                    <ul>
                      <li>
                        <a href="/">网站首页</a>
                      </li>
                                            <li class="flexrow">
                        <a href="/aboutUs">关于我们</a>
                                                <img class="wapnav_ico"
                          src="../ldcms2024/images/jiantblue.png" alt>
                        <div class="wapernav">
                                                    <a href="/aboutUs"
                            title="公司简介">公司简介</a>
                                                    <a href="/aboutUs#abqywh"
                            title="企业文化">企业文化</a>
                                                    <a href="/aboutUs#abfzlc"
                            title="发展历程">发展历程</a>
                                                  </div>
                                              </li>
                                            <li class="flexrow">
                        <a href="/product">产品中心</a>
                                                <img class="wapnav_ico"
                          src="../ldcms2024/images/jiantblue.png" alt>
                        <div class="wapernav">
                                                    <a href="/product"
                            title="产品系列1">产品系列1</a>
                                                    <a href="/productSubpage"
                            title="产品系列2">产品系列2</a>
                                                  </div>
                                              </li>
                                            <li class="flexrow">
                        <a href="/solution">解决方案</a>
                                                <img class="wapnav_ico"
                          src="../ldcms2024/images/jiantblue.png" alt>
                        <div class="wapernav">
                                                    <a href="/solution"
                            title="行业方案">行业方案</a>
                                                    <a href="/solution#fashzr"
                            title="社会责任">社会责任</a>
                                                  </div>
                                              </li>
                                            <li class="flexrow">
                        <a href="/serviceAndSupport">服务与支持</a>
                                                <img class="wapnav_ico"
                          src="../ldcms2024/images/jiantblue.png" alt>
                        <div class="wapernav">
                                                    <a href="/serviceAndSupport"
                            title="服务案例">服务案例</a>
                                                    <a href="/downloadCenter"
                            title="下载中心">下载中心</a>
                                                  </div>
                                              </li>
                                            <li class="flexrow">
                        <a href="/newsCenter">新闻中心</a>
                                                <img class="wapnav_ico"
                          src="../ldcms2024/images/jiantblue.png" alt>
                        <div class="wapernav">
                                                    <a href="/newsCenter"
                            title="公司新闻">公司新闻</a>
                                                    <a href="/industryNews"
                            title="行业资讯">行业资讯</a>
                                                  </div>
                                              </li>
                                            <li class="flexrow">
                        <a href="/contactUs">联系我们</a>
                                              </li>
                                          </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="wapsearch">
              <div class="wapsea_item flexrow">
                <form class="flexrow" method="get"
                  action="/contactUs">
                  <input type="text" name="search" id placeholder="请输入关键字">
                  <input  name="cid" type="hidden" value="128,144,147">
                  <button type="submit">
                    <img src="../ldcms2024/images/searchico.png" alt>
                  </button>
                  <div class="wapsea_close">
                    <img src="../ldcms2024/images/search_close.png" alt>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- top end -->

  <!-- banner -->
  <div class="banner">
    <!--  -->
    <!-- Swiper -->
    <div class="swiper-container bannerswiper">
      <div class="swiper-wrapper">
                <div class="swiper-slide">
          <img class="banner_img" src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/banner.jpg" alt="探索未知世界的源动力">
          <div class="banner_desc flexrow">
            <h2>探索未知世界的源动力</h2>
<p>A source of motivation to explore the unknown</p>          </div>
        </div>
                <div class="swiper-slide">
          <img class="banner_img" src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/banner2.jpg" alt="探索未知世界的源动力2">
          <div class="banner_desc flexrow">
                      </div>
        </div>
              </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination banner_pag"></div>
    </div>

  </div>
  <!-- banner end -->

  <!-- 产品中心 -->
    <section class="idx_item ">
    <div class="con1400">
      <div class="idx_titles">
        <h3>产品中心</h3>
      </div>
      <div class="idx_pros">
        <div class="container con1400">
          <ul class="row">
            <li class="col-lg-3 col-md-3 col-sm-6 col-xs-12 animate" data-animate="fadeInUp" data-duration="1s"
              data-delay="0.1s" v-for="product in products.slice(0,4)" :key="product.id">
              <a @click="jumpToProductSubpageByDetails(product)" :title="product.mainTitle">
                <img :src="product.indexPicture" :alt="product.mainTitle">
                <div class="idx_pros_desc flexrow">
                  <h3>{{product.mainTitle}}</h3>
                </div>
              </a>
            </li>
                      </ul>
        </div>
      </div>
      <div class="idxpros_more">
        <!-- <a href="/chanpinzhongxin.html">查看全部产品 +</a> -->
        <router-link to="/product" tag="button" class="btn btn-primary custom-btn">查看全部产品</router-link> 
      </div>
    </div>
  </section>
    <!-- 产品中心 end -->

  <!-- 解决方案 -->
    <section class="idx_item ">
    <div class="con1400">
      <div class="idx_titles">
        <h3>解决方案</h3>
      </div>
    </div>
    <div class="idx_fa  animate" data-animate="fadeInUp" data-duration="1.5s" data-delay="0.1s">
      <ul>
                <li class="idx_falist1 active " style="background-image: url('https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/idxfaimg3.jpg');">
          <a href="/solution" title="汽车设备智能制造">
            <div class="idxfa_title flexrow">
              <h3>汽车设备智能制造</h3>
            </div>
            <div class="idx_fadesc  animate" data-animate="fadeIn" data-duration="1.5s" data-delay="0.1s">
              <h3>汽车设备智能制造</h3>
              <div class="idx_fadesc_item ">
                <p>赋能汽车“智"巩固汽车行业智能制造地位，驱动产业链变革。造，专注汽车连接系统，ADAS，控制器，热管理系统，电驱动系统的组装和测试解决方案，提供大批量PC板加工所需的高速插针和智能分板标准设备，助力客户提升生产力。</p>
                <!-- <button>了解更多 ></button> -->
                <router-link to="/solution" tag="button">了解更多 ></router-link>
              </div>
            </div>
          </a>
        </li>
                <li class="idx_falist2" style="background-image: url('https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/idxfaimg2.jpg');">
          <a href="/solution" title="汽车设备智能制造_copy">
            <div class="idxfa_title flexrow">
              <h3>汽车设备智能制造_copy</h3>
            </div>
            <div class="idx_fadesc  animate" data-animate="fadeIn" data-duration="1.5s" data-delay="0.1s">
              <h3>汽车设备智能制造_copy</h3>
              <div class="idx_fadesc_item ">
                <p>赋能汽车“智"巩固汽车行业智能制造地位，驱动产业链变革。造，专注汽车连接系统，ADAS，控制器，热管理系统，电驱动系统的组装和测试解决方案，提供大批量PC板加工所需的高速插针和智能分板标准设备，助力客户提升生产力。</p>
                <!-- <button>了解更多 ></button> -->
                <router-link to="/solution" tag="button">了解更多 ></router-link>
              </div>
            </div>
          </a>
        </li>
                <li class="idx_falist3" style="background-image: url('https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024/images/idxfaimg1.jpg');">
          <a href="/solution" title="汽车设备智能制造_copy">
            <div class="idxfa_title flexrow">
              <h3>汽车设备智能制造_copy</h3>
            </div>
            <div class="idx_fadesc  animate" data-animate="fadeIn" data-duration="1.5s" data-delay="0.1s">
              <h3>汽车设备智能制造_copy</h3>
              <div class="idx_fadesc_item ">
                <p>赋能汽车“智"巩固汽车行业智能制造地位，驱动产业链变革。造，专注汽车连接系统，ADAS，控制器，热管理系统，电驱动系统的组装和测试解决方案，提供大批量PC板加工所需的高速插针和智能分板标准设备，助力客户提升生产力。</p>
                <!-- <button>了解更多 ></button> -->
                <router-link to="/solution" tag="button">了解更多 ></router-link>
              </div>
            </div>
          </a>
        </li>
              </ul>
    </div>
   
  </section>
    <!-- 解决方案 end -->

  <!-- 关于我们 -->
  <section class="idx_item" style="background-color:aliceblue">
    <div class="con1400">
            <div class="idx_titles">
        <h3>关于我们</h3>
      </div>
          </div>
    <div class="container con1400">
      <div class="row">
        <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12  animate" data-animate="fadeInLeft" data-duration="1s"
          data-delay="0.1s">
          <div class="idx_about_l">
                        <h3>专注网站建设，用心服务客户</h3>
            <p>
              历经10年的成长，我公司拥有一批资深的网页设计师与网站开发程序员，具有强大的技术力量。他们都是一批来自信息技术前沿阵地的优秀年轻人，团结务实的工作态度，富有激情的设计理念和创作情神，一贯的以客户需求为导向，广泛赢得了业界的赞誉和客户的信任。依托我公司强大的设计团队，可承接各大中小型公司网站，政府网站、集团网站、电子商务网站，网站功能及美工设计可根据客户的不同需要进行量身订制。并对所服务的客户网站维...            </p>
            <!-- <a href="/gongsijianjie.html">了解更多 ></a> -->
            <router-link to="/aboutUs" tag="button" class="btn btn-primary">了解更多</router-link>
                        <div class="idx_about_num">
              <div class="numCount">
                <ul class="flexrow">
                  <li>
                    <div class="item">
                      <div class="numU">
                        <span class="numCX" data-startval="0" data-endval="10" data-speed="7"
                          data-decimals="0" id="mumC1">10</span>
                        <sup class="unitC">+</sup>
                      </div>
                      <div class="indc">10年核心团队经验</div>
                    </div>
                  </li>
                  <li>
                    <div class="item">
                      <div class="numU">
                        <span class="numCX" data-startval="0" data-endval="20" data-speed="7"
                          data-decimals="0" id="mumC2">20</span>
                        <sup class="unitC">+</sup>
                      </div>
                      <div class="indc">超20项知识产权</div>
                    </div>
                  </li>
                  <li>
                    <div class="item">
                      <div class="numU">
                        <span class="numCX" data-startval="0" data-endval="3000" data-speed="7"
                          data-decimals="0" id="mumC3">3000</span>
                        <sup class="unitC">+</sup>
                       
                      </div>
                      <div class="indc">服务品牌客户超3000家</div>
                    </div>
                  </li>
                  <li>
                    <div class="item">
                      <div class="numU">
                        <span class="numCX" data-startval="0" data-endval="50" data-speed="7"
                          data-decimals="0" id="mumC4">50</span>
                        <sup class="unitC">+</sup>
                      </div>
                      <div class="indc">培养技术人员50多名</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12  animate" data-animate="fadeInRight" data-duration="1.5s"
          data-delay="0.1s">
                    <div class="idx_about_r">
            <img src="../ldcms2024/images/background3.webp" alt="专注网站建设，用心服务客户">
          </div>
                  </div>
      </div>
    </div>

  </section>
  <!-- 关于我们 end -->

  <!-- 案例展示 -->
    <section class="idx_item idx_hzitem">
    <div class=" con1400">
      <div class="idx_titles">
        <h3>案例展示</h3>
      </div>
            <template>  
              <div class="block">  
                <el-carousel height="500px">  
                  <el-carousel-item v-for="theServiceAndSupport in theServiceAndSupports.slice(0, 8)" :key="theServiceAndSupport.id" class="carousel-item-with-bg">  
                    <div  @click="$router.push(`/serviceAndSupportDetails?id=${theServiceAndSupport.id}`)">
                      <div class="carousel-item-content">  
                      <img :src="theServiceAndSupport.picture" class="carousel-image" />  
                      <div class="carousel-text">  
                        <p class="right-aligned-theNewsText">{{ theServiceAndSupport.mainTitle }}</p>  
                        <p class="right-aligned-theNewsText2">{{ theServiceAndSupport.content.slice(0, 90) + (theServiceAndSupport.content.length > 90 ? '......' : '') }}</p>  
                        <p class="right-aligned-theNewsText3">{{ theServiceAndSupport.creationDate.slice(0,10) }}</p>
                        <p class="right-aligned-theNewsText4">阅读全文></p>
                      </div>  
                    </div> 
                    </div>
                  </el-carousel-item>  
                </el-carousel>  
              </div>  
            </template>    

            
    </div>
  </section>
    <!-- 案例展示 end -->

  <!-- 新闻中心 -->
    <section class="idx_item idx_news">
    <div class="con1400">
      <div class="idx_titles">
        <h3>新闻中心</h3>
      </div>
    </div>
    <div class="container con1400">
      <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 animate" data-animate="zoomIn" data-duration="1.5s"
          data-delay="0.1s" v-for="theNewsCenter in theNewsCenters.slice(0,3)" :key="theNewsCenter.id">
          <div class="idxnews_item">
            <a href="/industryNews" title="WBE世界电池展圆满落幕 | 感恩遇见，期待再聚_copy_copy">
              <div class="idxnews_img">
                <img :src="theNewsCenter.picture" alt="WBE世界电池展圆满落幕 | 感恩遇见，期待再聚_copy_copy">
              </div>
              <div class="idxnews_desc">
                <h4>行业资讯</h4>
                <h3>{{ theNewsCenter.mainTitle }}</h3>
                <p>
                 {{ theNewsCenter.content }}              </p>
                <!-- <button>了解更多 ></button> -->
                <router-link to="/newsCenter" tag="button">了解更多 ></router-link>
              </div>
            </a>
          </div>
        </div>
                
              </div>
    </div>
    <div class="idxnews_link">
      <!-- <a href="/xinwenzhongxin.html">查看全部新闻 +</a> -->
      <a><router-link to="/newsCenter" tag="button" style="width: 297px ;height: 80px;font-size: 25px;color:chocolate;">查看全部新闻 +</router-link></a>
    </div>
  </section>
    <!-- 新闻中心 end -->

  <!-- 合作客户 -->
  <section class="idx_item idx_kehu">
    <div class="con1400">
            <div class="idx_titles">
        <h3>合作客户</h3>
      </div>
            <div class="idxkehu_item animate" data-animate="fadeInUp" data-duration="1.5s" data-delay="0.1s">
        <!-- Swiper -->
        <div class="swiper-container idxkh_swiper">
          <div class="swiper-wrapper">
                        <div class="swiper-slide">
              <div class="idxkh_simg">
                <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024//images/logo1.png" alt="合作客户_copy_copy_copy" title="合作客户_copy_copy_copy">
              </div>
            </div>
                        <div class="swiper-slide">
              <div class="idxkh_simg">
                <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024//images/logo1.png" alt="合作客户_copy_copy_copy" title="合作客户_copy_copy_copy">
              </div>
            </div>
                        <div class="swiper-slide">
              <div class="idxkh_simg">
                <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024//images/logo1.png" alt="合作客户_copy_copy_copy" title="合作客户_copy_copy_copy">
              </div>
            </div>
                        <div class="swiper-slide">
              <div class="idxkh_simg">
                <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024//images/logo1.png" alt="合作客户_copy_copy" title="合作客户_copy_copy">
              </div>
            </div>
                        <div class="swiper-slide">
              <div class="idxkh_simg">
                <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024//images/logo1.png" alt="合作客户_copy_copy" title="合作客户_copy_copy">
              </div>
            </div>
                        <div class="swiper-slide">
              <div class="idxkh_simg">
                <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024//images/logo1.png" alt="合作客户_copy" title="合作客户_copy">
              </div>
            </div>
                        <div class="swiper-slide">
              <div class="idxkh_simg">
                <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024//images/logo1.png" alt="合作客户_copy_copy_copy" title="合作客户_copy_copy_copy">
              </div>
            </div>
                        <div class="swiper-slide">
              <div class="idxkh_simg">
                <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024//images/logo1.png" alt="合作客户_copy_copy_copy" title="合作客户_copy_copy_copy">
              </div>
            </div>
                        <div class="swiper-slide">
              <div class="idxkh_simg">
                <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024//images/logo1.png" alt="合作客户_copy_copy_copy" title="合作客户_copy_copy_copy">
              </div>
            </div>
                        <div class="swiper-slide">
              <div class="idxkh_simg">
                <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024//images/logo1.png" alt="合作客户_copy_copy" title="合作客户_copy_copy">
              </div>
            </div>
                        <div class="swiper-slide">
              <div class="idxkh_simg">
                <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024//images/logo1.png" alt="合作客户_copy_copy" title="合作客户_copy_copy">
              </div>
            </div>
                        <div class="swiper-slide">
              <div class="idxkh_simg">
                <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024//images/logo1.png" alt="合作客户_copy" title="合作客户_copy">
              </div>
            </div>
                        <div class="swiper-slide">
              <div class="idxkh_simg">
                <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024//images/logo1.png" alt="合作客户_copy_copy" title="合作客户_copy_copy">
              </div>
            </div>
                        <div class="swiper-slide">
              <div class="idxkh_simg">
                <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024//images/logo1.png" alt="合作客户_copy_copy" title="合作客户_copy_copy">
              </div>
            </div>
                        <div class="swiper-slide">
              <div class="idxkh_simg">
                <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024//images/logo1.png" alt="合作客户_copy_copy" title="合作客户_copy_copy">
              </div>
            </div>
                        <div class="swiper-slide">
              <div class="idxkh_simg">
                <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024//images/logo1.png" alt="合作客户_copy" title="合作客户_copy">
              </div>
            </div>
                        <div class="swiper-slide">
              <div class="idxkh_simg">
                <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024//images/logo1.png" alt="合作客户_copy" title="合作客户_copy">
              </div>
            </div>
                        <div class="swiper-slide">
              <div class="idxkh_simg">
                <img src="https://demo.ldcms.com.cn/assets/addons/ldcms/ldcms2024//images/logo1.png" alt="合作客户" title="合作客户">
              </div>
            </div>
                      </div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination idxkh_spagination"></div>
      </div>
    </div>

  </section>
  <!-- 合作客户 end -->

  <!-- 底部表单 -->
<section class="idx_footmess animate"
    data-animate="fadeInUp" data-duration="1.5s" data-delay="0.1s">
      <div class="con1400">
                <div class="idx_ftitles">
          <h3>专业技术团队 提供全方位系统解决方案</h3>
          <p>如有任何疑问 请随时与我们联系</p>
        </div>
                <div class="idx_fmess">
          <form class="idx_fform flexrow">
            <input type="text" name="uname" v-model="footName" placeholder="您的姓名">
            <input type="text" name="mobile" v-model="footPhone" placeholder="您的电话">
            <input class="idx_fform3" type="text" v-model="footContent" name="remark" placeholder="您的留言">
            <!-- <input type="hidden" name="__token__"  value="287ea90fccc6d81e29012d9beae40b71" />             -->
            <!-- <button type="submit">立即提交</button> -->
            <el-button :plain="true" @click="footOpen" type="primary">立即提交</el-button>
          </form>
        </div>
      </div>
    </section>
  <!-- 底部表单 end -->

  <!-- 底部导航 -->
   <section class="foot">
      <div class="con1400">
        <div class="foottop flexrow">
          <div class="footlogo">
            <a href="/">
              <img src="../ldcms2024/images/indexLogo2.png" alt="苏州竹子网络科技有限公司">
            </a>
          </div>
          <div class="footlinks">
            <ul class="flexrow">
              <li>
                <a href="JavaScript:;">
                  <img class="fewmico" src="../ldcms2024/images/douyin.svg" alt>
                </a>
                <div class="fewmimg">
                  <img src="../ldcms2024/images/wxpicture.jpg" alt="">
                  <p>抖音二维码</p>
                </div>
              </li>
              <li>
                <a href="JavaScript:;">
                  <img class="fewmico" src="../ldcms2024/images/weixin.svg" alt>
                </a>
                <div class="fewmimg">
                  <img src="../ldcms2024/images/wxpicture.jpg" alt="">
                  <p>微信公众号</p>
                </div>
              </li>
              <li>
                <a href="JavaScript:;">
                  <img class="fewmico" src="../ldcms2024/images/qq.svg" alt>
                </a>
                <div class="fewmimg">
                  <img src="../ldcms2024/images/wxpicture.jpg" alt="">
                  <p>QQ群:961718787</p>
                </div>
              </li>
              <li>
                <a href="JavaScript:;">
                  <img class="fewmico" src="../ldcms2024/images/xhs.svg" alt>
                </a>
                <div class="fewmimg">
                  <img src="../ldcms2024/images/wxpicture.jpg" alt="">
                  <p>小红书二维码</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="footmid flexrow">
          <div class="footmid_l flexrow">
                        <dl>
              <dt><a href="/aboutUs"
                  title="关于我们">关于我们</a></dt>
                            <dd><a href="/aboutUs"
                  title="公司简介">公司简介</a></dd>
                            <dd><a href="/aboutUs#abqywh"
                  title="企业文化">企业文化</a></dd>
                            <dd><a href="/aboutUs#abfzlc"
                  title="发展历程">发展历程</a></dd>
                          </dl>
                        <dl>
              <dt><a href="/product"
                  title="产品中心">产品中心</a></dt>
                            <dd><a href="/product"
                  title="产品系列1">产品系列1</a></dd>
                            <dd><a href="/productSubpage"
                  title="产品系列2">产品系列2</a></dd>
                          </dl>
                        <dl>
              <dt><a href="/solution"
                  title="解决方案">解决方案</a></dt>
                            <dd><a href="/solution"
                  title="行业方案">行业方案</a></dd>
                            <dd><a href="/solution#fashzr"
                  title="社会责任">社会责任</a></dd>
                          </dl>
                        <dl>
              <dt><a href="/serviceAndSupport"
                  title="服务与支持">服务与支持</a></dt>
                            <dd><a href="/serviceAndSupport"
                  title="服务案例">服务案例</a></dd>
                            <dd><a href="/downloadCenter"
                  title="下载中心">下载中心</a></dd>
                          </dl>
                        <dl>
              <dt><a href="/newsCenter"
                  title="新闻中心">新闻中心</a></dt>
                            <dd><a href="/newsCenter"
                  title="公司新闻">公司新闻</a></dd>
                            <dd><a href="/industryNews"
                  title="行业资讯">行业资讯</a></dd>
                          </dl>
                        <dl>
              <dt><a href="/contactUs"
                  title="联系我们">联系我们</a></dt>
                          </dl>
                      </div>
          <div class="footmid_r">
            <ul>
              <li>
                <div class="footmid_rdesc">
                  <p>4006-711-886</p>
                </div>
              </li>
              <li>
                <div class="footmid_rdesc">
                  <p>苏州市相城区相城大道666号中翔大厦15012</p>
                </div>
              </li>
              <li>
                <div class="footmid_rdesc">
                  <p>erweima@foxmail.com</p>
                </div>
              </li>
            </ul>
            <div class="footmid_ewm ">
              <ul class="flexrow">
                <li>
                  <img src="../ldcms2024/images/wxpicture.jpg" alt>
                  <p>咨询微信客服</p>
                </li>
                <li>
                  <img src="../ldcms2024/images/wxpicture.jpg" alt>
                  <p>微信公众号</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="footcopy">
      <div class="con1400">
        <div class="footcitem flexrow">
          <div class="footc_l">
            <span class="footc_ldesc">© 2011-2024 苏州竹子网络科技有限公司 版权所有</span>
            <span>备案号：<a href="https://beian.miit.gov.cn/"
                target="_blank" rel="noopener noreferrer">苏ICP备14060880号</a></span>
                              <span><a href="/index" target="_blank">免责声明</a></span>
                            <span><a href="/index" target="_blank">隐私协议</a></span>
                        </div>
          <div class="footc_r">
            <p>友情链接</p>
            <div class="footc_rlinks">
              <ul>
                                <li><a href="/index" target="_blank">LDcms网站系统</a></li>
                                <li><a href="/index" target="_blank">苏州网站建设</a></li>
                                <li><a href="/index" target="_blank">苏州网站模板</a></li>
                              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="xuanfu">
        <ul>
          <li class="xfwxitem">
            <a href="	" target="_blank">
              <div class="xfimg flexrow">
                <img src="../ldcms2024/images/xfico1.png" alt>
              </div>
              <div class="xfwx">
                <img src="../ldcms2024/images/wxpicture.jpg" alt="咨询微信客服">
                <p class="xfwxdesc">咨询微信客服</p>
              </div>
            </a>
          </li>
          <li class="xftelitem">
            <div class="xfimg flexrow">
              <img src="../ldcms2024/images/xfico2.png" alt>
            </div>
            <div class="xfwx xftel">
              <p>4006-711-886</p>
              <span class="xfteldesc">立即获取方案或咨询</span>
            </div>
          </li>
          <li class="xftopitem">
            <div class="xfimg flexrow" id="goToTop">
              <img src="../ldcms2024/images/xfico3.png" alt>
              <p>top</p>
            </div>
          </li>
        </ul>
      </div>
    </section>
  <!-- 底部导航 end -->

    </div>
  
  </template>
  
  <script>
 // 引入Swiper和Swiper的CSS（通常需要引入CSS以确保样式正确）  
import Swiper from 'swiper';  
import jQuery from 'jquery';
import axios from 'axios';
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name:'index',

    data() {  
      return { 

      baseUrl:this.GLOBAL.BASE_URL,

        // 底部表单
      footName: '',  
      footPhone: '',  
      footContent: '',
      

      //产品中心
      products: [],

      //服务与支持
      theServiceAndSupports:[],

      //新闻中心
      theNewsCenters:[],

      }
    },

    created() {  
      this.selectAllByProduct();
      this.selectAllByServiceAndSupport();
      this.selectAllByNewsCenter();
    }, 

    methods: {

      selectAllByProduct() {  
      axios.get(this.baseUrl+'product/selectAllByProduct',{
      })
    .then(response => {  
          // 处理成功响应，例如显示成功消息 
        this.products = response.data.data;
        console.log("产品中心this.products",this.products)
        })
     .catch(error => {  
      // 处理错误  
      console.error(error);  
    });  

    },

    selectAllByServiceAndSupport() {  
      axios.get(this.baseUrl+'serviceAndSupport/selectAllByServiceAndSupport',{
      })
    .then(response => {  
          // 处理成功响应，例如显示成功消息 
        this.theServiceAndSupports = response.data.data;
        console.log("服务与支持this.theServiceAndSupports",this.theServiceAndSupports)
        this.firstPicture=this.theServiceAndSupports[0].picture;
        this.firstMainTitle=this.theServiceAndSupports[0].mainTitle;
        })
     .catch(error => {  
      // 处理错误  
      console.error(error);  
    });  

    },

    jumpToProductSubpageByDetails(product){
      this.$router.push(`/productSubpageByDetails?id=${product.id}`)
    },

    selectAllByNewsCenter() {  
      axios.get(this.baseUrl+'newsCenter/selectAllByNewsCenter',{
      })
    .then(response => {  
          // 处理成功响应，例如显示成功消息 
        this.theNewsCenters = response.data.data;
        console.log("新闻中心this.theNewsCenters",this.theNewsCenters)
        })
     .catch(error => {  
      // 处理错误  
      console.error(error);  
    });  

    },


      footOpen() {  
      axios.post(this.baseUrl+'contactUs/addContactUs',{
        name:this.footName,
        phone:this.footPhone,
        content:this.footContent
      },{
        headers: {  
        'Content-Type': 'application/json'  
        }  
       })  
    .then(response => {  
          // 处理成功响应，例如显示成功消息 
        console.log('Form submitted successfully', response.data); 
        if(response.data.code==474){
          this.$message({
          message: response.data.msg,
          type: 'warning'
        }); 
        }
        else if(response.data.code==475){
          this.$message({
          message: response.data.msg,
          type: 'warning'
        }); 
        }
        else if(response.data.code==476){
          this.$message({
          message: response.data.msg,
          type: 'error'
        }); 
        }
        else if(response.data.code==472){
          this.$message({
          message: response.data.msg,
          type: 'warning'
        }); 
        }
        else if(response.data.code==200){
          this.$message({
          message: response.data.msg,
          type: 'success'
        }); 
        }

      this.footResetForm();

        })
     .catch(error => {  
      // 处理错误  
      console.error(error);  
    });  

    },

    footResetForm() {  
        this.footName = '';  
        this.footPhone = '';  
        this.footContent = '' ;
    },

    },

    mounted(){
      // eslint-disable-next-line no-unused-vars
      var bannerswiper = new Swiper('.bannerswiper', {
        slidesPerView: 1,
        spaceBetween: 0,
        autoplay: true,
        speed: 1000,
        loop: true,
        pagination: {
          el: '.banner_pag',
          clickable: true,
        },
      });

     
    jQuery(document).ready(function () {
      jQuery(".idx_fa ul li").mouseenter(function () {
        jQuery(this).addClass("active").siblings().removeClass("active");
      })
      jQuery('.idx_falist1').mouseenter(function () {
        jQuery('.idx_falist1').css({ "left": "0px" });
        jQuery('.idx_falist2').css({ "left": "50%" });
        jQuery('.idx_falist3').css({ "left": "75%" });
      });
      jQuery('.idx_falist2').mouseenter(function () {
        jQuery('.idx_falist1').css({ "left": "0px" });
        jQuery('.idx_falist2').css({ "left": "25%" });
        jQuery('.idx_falist3').css({ "left": "75%" });
      });
      jQuery('.idx_falist3').mouseenter(function () {
        jQuery('.idx_falist1').css({ "left": "0px" });
        jQuery('.idx_falist2').css({ "left": "25%" });
        jQuery('.idx_falist3').css({ "left": "50%" });
      });
    
    });
   
  // eslint-disable-next-line no-unused-vars
  var idxhz_swiper = new Swiper('.idxhz_swiper', {
          slidesPerView: 3,
          spaceBetween: 30,
          loop: true,
          navigation: {
            nextEl: '.idxhz_next',
            prevEl: '.idxhz_prev',
          },
          breakpoints: {
            320: {  //当屏幕宽度大于等于320
              slidesPerView: 1,
              spaceBetween: 10
            },
            768: {  //当屏幕宽度大于等于768 
              slidesPerView: 2,
              spaceBetween: 20
            },
            1280: {  //当屏幕宽度大于等于1280
              slidesPerView: 3,
              spaceBetween: 30
            }
          },
        });

    // eslint-disable-next-line no-unused-vars
    var idxkh_swiper = new Swiper('.idxkh_swiper', {
        slidesPerView: 6,
        slidesPerColumn: 2,
        spaceBetween: 30,
        // loop: true,
        pagination: {
          el: '.idxkh_spagination',
          clickable: true,
        },

        breakpoints: {
          320: {  //当屏幕宽度大于等于320
            slidesPerView: 2,
            spaceBetween: 10
          },
          768: {  //当屏幕宽度大于等于768 
            slidesPerView: 4,
            spaceBetween: 20
          },
          1280: {  //当屏幕宽度大于等于1280
            slidesPerView: 6,
            spaceBetween: 30
          }
        },
      });      
   
  jQuery(document).ready(function(){
    
    // 电脑导航下滑时候样式
    jQuery(function () {
      var nav = jQuery(".top"); //得到导航对象baidu
      var win = jQuery(window); //得到窗口对象
      var sc = jQuery(document); //得到document文档对象。zhi
      win.scroll(function () {
        if (sc.scrollTop() >= 60) {
          nav.addClass("fixednav");
          jQuery(".xuanfu").fadeIn();
        } else {
          nav.removeClass("fixednav");
          jQuery(".xuanfu").fadeOut();
        }
      });
    });

    // 手机导航下滑时候样式
    jQuery(function () {
    var wapnav = jQuery(".waptop"); //得到导航对象baidu
    var wapwin = jQuery(window); //得到窗口对象
    var wapsc = jQuery(document); //得到document文档对象。zhi
    wapwin.scroll(function () {
      if (wapsc.scrollTop() >= 60) {
        wapnav.addClass("fixednav");
        // jQuery(".xuanfu").fadeIn();
      } else {
        wapnav.removeClass("fixednav");
        // jQuery(".xuanfu").fadeOut();
      }
    });
  });

    // 顶部搜索
    jQuery('.top_searchico').click(function(){
      jQuery('.searchitem').fadeIn();
    });
    jQuery('.searchclose').click(function(){
      jQuery('.searchitem').hide();
    });

      // 电脑导航下拉
      jQuery(".nav>ul>li").mouseenter(function () {
      jQuery(this)
        .find(".ernav")
        .slideDown()
        .end()
        .siblings("li")
        .find(".ernav")
        .hide();
    });
    jQuery(".ernav").mouseleave(function () {
      jQuery(this).hide();
    });

    // 返回顶部
    jQuery('#goToTop').click(function () {
      jQuery('html , body').animate({ scrollTop: 0 }, 'slow');
    });

    // 手机端语言切换
    jQuery('.waptop_langa').click(function(){
      jQuery('.langitem').fadeToggle();
    })

    // 手机端搜索效果
    jQuery('.waptop_rs').click(function(){
      jQuery('.wapsearch').fadeIn();
    });
    jQuery('.wapsea_close').click(function(){
      jQuery('.wapsearch').hide();
    });

    // 手机端导航栏目下拉效果
    jQuery('.wapnav_ico').click(function() {
      jQuery(this).toggleClass('active').parent('li').siblings().find('.wapnav_ico').removeClass('active');
      jQuery(this).next('.wapernav').slideToggle().end().parent('li').siblings().find('.wapernav').slideUp();
    });

    // 手机端导航下拉效果
    jQuery('.waptop_rnav').click(function(){
      jQuery('.wapnav').slideDown();
    })
    jQuery('.wapnavt_r').click(function(){
      jQuery('.wapnav').hide();
    });
  })    
   
                                  
}
}


</script>
  
<style scoped>
  
.custom-btn {  
  font-size: 25px;  
  width: 350px;  
  height: 75px;    
  display: flex; 
  justify-content: center; 
  align-items: center;  
  background-color: rgb(104, 105, 96);
}   

.carousel-item-content {  
  display: flex;  
  align-items: center; 
}  
  
.carousel-image {  
  flex: 1; 
  height: 500px;  
  object-fit: cover; 
  width: 50%; 
}  
  
.carousel-text {  
  flex: 1; 
  padding: 20px;  
  text-overflow: ellipsis; 
  display: -webkit-box; 
  -webkit-line-clamp: 10; 
  overflow: hidden;  
  -webkit-box-orient: vertical; 
  transform: translateY(-90px); 
}  
  
.right-aligned-theNewsText{
  margin: 0;
  font-size: 35px;
  color: #7c04ec;
  font-weight: bold; 
  text-align: center;

} 
.right-aligned-theNewsText2 {  
  
  margin: 0; 
  font-size: 20px;
  color: #000000; 
}  

.right-aligned-theNewsText3 {  
  
  margin: 0; 
  font-size: 20px;
  color: #000000;
  transform: translateY(50px); 
  margin-left: 20px;
}  

.right-aligned-theNewsText4 {  
  
  margin: 0; 
  font-size: 20px;
  color: #000000; 
  transform: translateY(22px);
  margin-left: 500px;
}  


.carousel-item-with-bg {  
  background-color: #f0f0f0;
  }

.pagination-container {  
  display: flex;   
  justify-content: center;   
  align-items: center; 
  height: 10vh;  
} 
</style>